import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { RemoteService } from '../server/remote.service';
import { UserService } from '../user/user.services';
import { Observable } from 'rxjs';

@Injectable()
export class AuthService {
  token: string;

  constructor(private remoteSrvc: RemoteService, private userSrvc: UserService
  , private router: Router) {}

  signupUser(email: string, password: string) {
    // your code for signing up the new user
  }

  signinUser(email: string, password: string) {
    // your code for checking credentials and getting tokens for for signing in user
  }

  logout() {
    localStorage.clear();
  }

  getToken() {
    return this.token;
  }

  isAuthenticated(){
    // here you can check if user is authenticated or not through his token
  
   }

}
