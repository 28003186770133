import { Injectable } from "@angular/core";

@Injectable()
export class UserService {
  public userInfo: any = undefined;
  public mode: string = "ro";
  public accesstype: string = "E";
  public position: any = undefined;
  public privileage_list: any = [];
  public access_token: any;
  levelname: any;
  department: any;

  constructor() {
    this.access_token = localStorage.getItem("token")
      ? localStorage.getItem("token").replace(/['"]+/g, "")
      : null;
    this.userInfo = localStorage.getItem("userinfo")
      ? JSON.parse(localStorage.getItem("userinfo"))
      : null;
    this.position = localStorage.getItem("position")
      ? JSON.parse(localStorage.getItem("position"))
      : null;
  }

  isValidSession() {
    if (localStorage.getItem("token")) {
      return true;
    } else {
      return false;
    }
  }

  getUserInfo() {
    return this.userInfo;
  }

  clearSession() {
    window.localStorage.clear();
    this.userInfo = undefined;
  }

  setUserInfo() {
    this.access_token = localStorage.getItem("token").replace(/['"]+/g, "");
    this.userInfo = localStorage.getItem("userinfo")
      ? JSON.parse(localStorage.getItem("userinfo"))
      : null;
    this.position = localStorage.getItem("position")
      ? JSON.parse(localStorage.getItem("position"))
      : null;
  }

  getRoleName() {
    return this.accesstype ? this.accesstype : "User";
  }

  getUserName() {
    return this.userInfo.name;
  }

  getPositionName() {
    if (!this.isEmpty(this.position)) {
      return this.position.name;
    } else {
      return "";
    }
  }

  getMemberID() {
    return this.userInfo ? this.userInfo.mid : null;
  }

  getConstituencyID() {
    if (this.userInfo.place.constituency) {
      return this.userInfo.place.constituency;
    } else {
      return null;
    }
  }

  getToken() {
    return this.access_token ? this.access_token : null;
  }

  getThuliStatus() {
    return this.userInfo.thuli;
  }

  getAdminConstituencyID() {
    return !this.isEmpty(this.position) && this.position.place
      ? this.position.place.id
      : null;
  }

  getAdminConstituencyName() {
    return !this.isEmpty(this.position) && this.position.place.name
      ? this.position.place.name
      : null;
  }

  getLevel() {
    return !this.isEmpty(this.position) && this.position.level.level
      ? this.position.level.level
      : null;
  }

  isEmpty(obj) {
    return Object.entries(obj).length === 0;
  }
}
