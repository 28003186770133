import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./shared/auth/auth-guard.service";

const appRoutes: Routes = [
  {
    path: "",
    // component: HomepageComponent
    loadChildren: () =>
      import("./pages/homepage/homepage.module").then((m) => m.HomepageModule),
  },
  // { path: '', component: FullLayoutComponent, data: { title: 'full Views' }, children: Full_ROUTES, canActivate: [AuthGuard] },
  // { path: '', component: ContentLayoutComponent, data: { title: 'content Views' }, children: CONTENT_ROUTES, canActivate: [AuthGuard] },
  {
    path: "home",
    loadChildren: () =>
      import("./pages/homepage/homepage.module").then((m) => m.HomepageModule),
  },
  {
    path: "summary",
    loadChildren: () =>
      import("./pages/constituency-summary/constituency-summary.module").then(
        (m) => m.ConstituencySummaryModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "goals",
    loadChildren: () =>
      import("./pages/constituency-goals/constituency-goals.module").then(
        (m) => m.ConstituencyGoalsModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "memberdetails",
    loadChildren: () =>
      import("./pages/member-details/member-details.module").then(
        (m) => m.MemberDetailsModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "memberform",
    loadChildren: () =>
      import("./pages/member-form/member-form.module").then(
        (m) => m.MemberFormModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "representatives",
    loadChildren: () =>
      import("./pages/representative-form/representative-form.module").then(
        (m) => m.RepresentativeFormModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "payments",
    loadChildren: () =>
      import("./pages/payments/payments.module").then((m) => m.PaymentsModule),
    canActivate: [AuthGuard],
  },
  {
    path: "idcards",
    loadChildren: () =>
      import("./pages/memberidcards/memberidcards.module").then(
        (m) => m.MemberidcardsModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "district/dashboard",
    loadChildren: () =>
      import("./pages/districtreport/districtreport.module").then(
        (m) => m.DistrictreportModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "admininfo",
    loadChildren: () =>
      import("./pages/admindetails/admindetails.module").then(
        (m) => m.AdmindetailsModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "login",
    loadChildren: () =>
      import("./pages/login/login-page.module").then((m) => m.LoginModule),
  },
  {
    path: "realtime_stats",
    loadChildren: () =>
      import("./pages/public-dashboard/public-dashboard.module").then(
        (m) => m.PublicDashboardModule
      ),
  },
  {
    path: "pollingstations",
    loadChildren: () =>
      import("./pages/pollingstations/pollingstations.module").then(
        (m) => m.PollingstationsModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "forgotpassword",
    loadChildren: () =>
      import("./pages/forgot-password/forgot-password.module").then(
        (m) => m.ForgotPasswordModule
      ),
    data: {
      title: "Forgot Password Page",
    },
  },
  {
    path: "changeareas",
    loadChildren: () =>
      import("./pages/createareas/createareas.module").then(
        (m) => m.CreateareasModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "userhome",
    loadChildren: () =>
      import("./pages/userhome/userhome.module").then((m) => m.UserhomeModule),
    canActivate: [AuthGuard],
  },
  {
    path: "profile",
    loadChildren: () =>
      import("./pages/myprofile/myprofile.module").then(
        (m) => m.MyprofileModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "paymenthistory",
    loadChildren: () =>
      import("./pages/paymenthistory/paymenthistory.module").then(
        (m) => m.PaymenthistoryModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "areaevents",
    loadChildren: () =>
      import("./pages/areaevents/areaevents.module").then(
        (m) => m.AreaeventsModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "createdlist",
    loadChildren: () =>
      import("./pages/membersbyme/membersbyme.module").then(
        (m) => m.MembersbymeModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "messageunit",
    loadChildren: () =>
      import("./pages/messagecomp/messagecomp.module").then(
        (m) => m.MessagecompModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "infradata",
    loadChildren: () =>
      import("./pages/infradocuments/infradocuments.module").then(
        (m) => m.InfradocumentsModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "paymentresponse",
    loadChildren: () =>
      import("./pages/paymentresponse/paymentresponse.module").then(
        (m) => m.PaymentresponseModule
      ),
  },
  {
    path: "localbody",
    loadChildren: () =>
      import("./pages/localbody/localbody.module").then(
        (m) => m.LocalbodyModule
      ),
  },
  {
    path: "social/meetup",
    loadChildren: () =>
      import("./pages/eventregistration/eventregistration.module").then(
        (m) => m.EventregistrationModule
      ),
  },
  {
    path: "evententry",
    loadChildren: () =>
      import("./pages/eventattendence/eventattendence.module").then(
        (m) => m.EventattendenceModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "contacts",
    loadChildren: () =>
      import("./pages/porupaalar-details/porupaalar-details.module").then(
        (m) => m.PorupaalarDetailsModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "itwing",
    loadChildren: () =>
      import("./pages/joinitwing/joinitwing.module").then(
        (m) => m.JoinitwingModule
      ),
  },

  {
    path: "nonadminlist",
    loadChildren: () =>
      import("./pages/nonadminlocations/nonadminlocations.module").then(
        (m) => m.NonadminlocationsModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "dashboard",
    loadChildren: () =>
      import("./pages/dashboard/dashboard.module").then(
        (m) => m.DashboardModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "hierarcy/update",
    loadChildren: () =>
      import("./pages/lbstructure/lbstructure.module").then(
        (m) => m.LbstructureModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "buildarea",
    loadChildren: () => 
      import("./pages/build-area/build-area.module").then(
        (m) => m.BuildAreaModule
      ),
      canActivate: [AuthGuard],
  },
  {
    path: "userinfocheck",
    loadChildren: () =>
      import("./pages/userinfocheck/userinfocheck.module").then(
        (m) => m.UserinfocheckModule
      ),
  },
  {
    path: "basestructure/info",
    loadChildren: () =>
      import("./pages/basestructureinfo/basestructureinfo.module").then(
        (m) => m.BasestructureinfoModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "basestructure/reps",
    loadChildren: () =>
      import("./pages/basestructurereps/basestructurereps.module").then(
        (m) => m.BasestructurerepsModule
      ),
    canActivate: [AuthGuard],
  },

  {
    path: "",
    redirectTo: "",
    pathMatch: "full",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
