import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from "@angular/router";
import { Injectable } from "@angular/core";
import { AuthService } from "./auth.service";
import { Observable } from "rxjs";
import { UserService } from "../user/user.services";
import { RemoteService } from "../server/remote.service";
import Swal from "sweetalert2";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private remoteSrvc: RemoteService,
    private userSrvc:UserService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {

      if (localStorage.getItem("token") == null) {
        Swal.fire("", "Session Expired", "error");
        this.router.navigate(["home"]);
        resolve(false);
      } else {
        
        this.remoteSrvc.isSessionvalid().subscribe({
            next: (val) =>{
              this.remoteSrvc.isLocalAdmin().subscribe(
                { 
                    next: (val) => {
                        this.userSrvc.privileage_list = val['privileages'];
                        this.userSrvc.mode = val['mode'];
                        this.userSrvc.accesstype = val['accesstype']
                      resolve(true);
                      
                    },error:()=>{
                       this.userSrvc.privileage_list = [];
                       this.router.navigate(['home']);
                       resolve(false)
                    }
                })
              },
            error:()=> {
              this.router.navigate(["home"]);
              Swal.fire("", "Session Expired", "error");
              resolve(false);
              }
          })
        }
    });
  }
}
